.box{
    padding: 1rem;
    background: var(--color-white);
    border-radius: .4rem;
}

.box-details span {
    background: rgba(174, 25, 20, .22);
    color: var(--color-primary);
    padding: .4rem .7rem;
    font-size: .8rem;
    font-weight: 400;
    border-radius: 1.5rem;
}

.box-details h1 {
    font-size: 1.3rem;
    font-weight: 400;
    margin: 0.5rem 0 0;
}

.box-details address {
    font-size: 1rem;
    color: var(--color-grey-ligth);
    margin: 0;
}

.box-details h2 {
    font-size: 1.7rem;
    font-weight: 400;
    margin: .6rem 0;
    color: var(--color-primary);
}

.box-details ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    font-size: .8rem;
}

.box-details ul li {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.box-details ul li i {
    width: 30px;
    height: 30px;
    color: var(--color-grey);
    font-size: .8rem;
    background: var(--color-background);
    border-radius: 50% 50%;
    margin-right: .4rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* DESCRIÇÃO DO IMÓVEL */
.box-description {
    margin-top: 2rem;
}

.box-description h2 {
    font-size: 1rem;
}

/* LISTA DE CARACTERISTICAS */
.box-features {
    margin-top: 2rem;
}

.box-features h2 { 
    font-size: 1rem;
}

.box-features ul {
    display: block;
    column-count: 3;
    line-height: 25px;
    border: 0;
}

.box-features ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: .7rem;

    font-size: .85rem;
    font-weight: 300;
}

.box-features ul li i {
    color: var(--color-primary);
    background: rgba(174, 25, 20, .22);
    font-size: .5rem;
    padding: .4rem;
    border-radius: 50% 50%;
    margin-right: .5rem;
}

/* LOCALIZAÇÃO */
.box-location {
    margin-top: 2rem;
    padding: 1rem .5rem;
}

.box-location h2 {
    font-size: 1rem;
}

.box-location .block-map {
    position: relative;
    width: 100%;
    height: 400px;
    background-image: url('../../../../assets/images/map.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.box-location .block-map .content-map {
    width: 100%;
    height: 100%;
    background: rgba(245, 245, 245, .5);  
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
}

.box-location .block-map .content-map span {
    padding: 1rem;
    border: 1px solid var(--color-primary);
    border-radius: .4rem;
    background: var(--color-primary);
    color: var(--color-white);
    cursor: pointer;
}

.box-location .popup-map .leaflet-popup-content-wrapper {
    background: rgba( 86, 86, 86, .5);
    border-radius: 8px;
    box-shadow: none
}

.box-location .popup-map .leaflet-popup-content {
    font-size: 13px;
    font-weight: 500;    
    margin: 6px 10px;
    color: var(--color-white);
}

.box-location .popup-map .leaflet-popup-tip-container {
    display: none;
}

@media(max-width: 767px) {
    .box-details ul li {
        margin-bottom: .5rem;
    }

    .box-features ul {
        columns: 2;
    }
}

@media(max-width: 450px) {
    .box-features ul {
        columns: 1;
    }
}