.drawer-menu {
    position: fixed;
    width: 100%;
    max-width: 380px;
    height: 100%;
    padding: 20px 0;
    top: 0;
    right: 0;
    z-index: 3000;
    transition: right .3s;
    background: rgba(0,0,0,.90);
    overflow: auto;
    border-left: 2px solid var(--color-yellow);
}

.no-drawer-menu {
    right: -480px;
}

.overlay {
    position: fixed;
    width: 100%;    
    height: 100%;
    background: var(--color-black);
    top: 0;
    left: 0;
    z-index: 2000;
    transition: .5s;
    opacity: 0.3;
}

.no-overlay {
    pointer-events: none;
    opacity: 0;
}

.drawer-menu .box-buttons,
.drawer-menu .box-buttons a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.drawer-menu .box-buttons {
    flex-wrap: wrap;
}

.drawer-menu .box-buttons a {
    flex-direction: column;
    font-size: .8rem;
    color: var(--color-grey-secondary);

    width: 40%;
    margin: 10px;
    height: 6rem;
    
    border: 1px solid var(--color-grey-secondary);
    transition: .5s;
}

.drawer-menu .box-buttons a:hover {
    color: var(--color-white);
    background: var(--color-primary);
}

.drawer-menu .box-buttons a svg,
.drawer-menu .box-buttons a i {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: var(--color-yellow);
}

.drawer-menu .box-services,
.drawer-menu .area-cliente {
   display: flex;
   justify-content: space-evenly;

}

.drawer-menu .box-services a,
.drawer-menu .area-cliente a {
    border: 1px solid var(--color-primary-lighter);
    color: var(--color-grey-secondary);
    transition: .5s;
    font-size: .9rem;
}

.drawer-menu .box-services a:hover {
    color: var(--color-white);
}

.drawer-menu .box-services a i {
    margin-right: 10px;
    font-size: 1rem;
}

.drawer-menu .area-cliente {
    padding: 0 1.5rem;
    flex-direction: column;
}

.drawer-menu .area-cliente h2 {
    color: var(--color-yellow);
    font-size: .9rem;
    margin-top: 1rem;
    text-transform: uppercase;
}

.drawer-menu .area-cliente a {  
    padding: .6rem;
    font-size: .9rem;
    border: 1px solid var(--color-grey-secondary);
    margin-bottom: 1rem;
}

.drawer-menu .area-cliente a:hover {
    background: var(--color-primary);
}

.drawer-menu hr {
    max-width: 90%;
    margin: 1rem auto;
    background: var(--color-grey);
}

@media(max-width:460px) {
    .drawer-menu {
        padding: 20px 0px 0;
    }

    .drawer-menu .box-buttons a {
        margin: 4px;
    }
}