#form-result {
    background: var(--color-white);
    width: 100%;
    padding: 0 1rem;
    height: max-content;
    border-radius: .4rem;
    overflow: hidden;
}

#form-result .box-especial {
    background: var(--color-primary);
    margin: 0 -1rem 15px;
    padding: 1rem;
}

#form-result .box-especial fieldset label {
    width: 100%;
}

#form-result .box-especial fieldset input {
    max-width: 48%;
}

#form-result .box-especial label {
    color: var(--color-white);
    font-size: .85rem;
}

#form-result select,
#form-result input, 
#form-result .select2-container--default .select2-selection--single,
#form-result .select2-container--default .select2-selection--single .select2-selection__rendered {
    margin-bottom: 1rem;
    height: 48px;
    font-weight: 300;
    line-height: 2.5;
    outline: none;
}

#form-result .select2-container--default .select2-selection--single,
#form-result .select2-container--default .select2-selection--single .select2-selection__rendered  {
    margin-bottom: 0;
}

#form-result fieldset {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
}

#form-result fieldset label {
    font-size: .85rem;
    margin: 0;
}

#form-result fieldset select {
    width: 50px;
    border: 1px solid var(--color-primary) !important;
    padding: 4px;
}

#form-result hr {
    margin: 0;
    border-top: 1px solid var(--color-primary);
}

#form-result .box-feature-list {    
    display: inline-flex;
    flex-direction: column;
    padding:  1rem 0;
}

#form-result .box-feature-list label{
    font-size: .8rem;
}

#form-result .box-feature-list .MuiTypography-body1{
    font-weight: 300;
    font-size: .9rem;
}

#form-result .box-feature-list .MuiCheckbox-colorPrimary{
    color: var(--color-primary);
}

#form-result .box-feature-list .PrivateSwitchBase-root-1 {
   padding: .2rem;
}

#form-result .box-feature-list .MuiFormControlLabel-root {
    margin: 0;
}

#form-result .box-feature-list .PrivateSwitchBase-input-4 {
    height: 100%;
    margin: 0;
}

#form-result .btn-wrap{    
    background: var(--color-white);
    position: sticky;
    bottom: 0;
    text-align: center;
    padding: 10px;
    display: none;
    margin: 0 -14px;
}

#form-result .btn-wrap .btn-primary {
    background: var(--color-primary);
    border: 0;
    margin: 0 auto;
}

@media(max-width: 900px) {
    #search-result-container .topo-content {
        display: block;
    }

    #search-result-container .topo-content h1 {
        margin-bottom: 1rem;
    }

    #search-result-container .content-search-result {
        width: 100% !important;
        padding-left: 0 !important;
    }

    #form-result .btn-wrap{           
        display: block;
    }
}