.popup-map-card-imovel .leaflet-popup-content {
    margin: 0;
    width: initial;
}

.popup-map-card-imovel .imovel-card {
    margin-bottom: 0;
    height: auto;
}

.popup-map-card-imovel .box-buttons,
.popup-map-card-imovel .wrapper ul li i,
.popup-map-card-imovel .footer {
    display: none;
}

.popup-map-card-imovel .box-image {
    width: 230px;
}

.popup-map-card-imovel .box-image img {
    height: 152px;
}

.popup-map-card-imovel .wrapper {
    padding: .5rem;
}

.popup-map-card-imovel .wrapper h2 {
   font-size: .9rem;
}

.popup-map-card-imovel .wrapper h3 {
   font-size: .85rem;
   margin: .4rem 0;
}

.popup-map-card-imovel .wrapper h3 span {
    font: 10rem;
    display: block;
}