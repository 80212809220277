.page-error-404 {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.page-error-404 .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 700px !important;
}

.page-error-404 .container h2 {
    font-size: 2rem;
    font-weight: 400;
    color: var(--color-grey);
}

.page-error-404 .container h1 {
    font-size: 5rem;
    font-weight: 400;
    text-align: center;
    color: var(--color-grey);
}

.page-error-404 .container h1 span {
    display: block;
    font-size: 7rem;
    font-weight: 700;
    color: var(--color-primary);
}

.page-error-404 .container p {
    font-size: .9rem;
    font-weight: 400;
    text-align: center;
        color: var(--color-grey);
}

.page-error-404 .container a {
    background: var(--color-primary);
    color: var(--color-white);
    font-weight: 400;
    padding: .5rem 1rem;

    border-radius: .5rem;
    transition: .5s;
}

.page-error-404 .container a:hover {
    opacity: .8;
}