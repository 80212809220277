#property-registration .content-form {
    padding-left: 15px;
    width: 100%;
    max-width: 40%;
}

.block-form {
    background: rgba(255, 255, 255, .89);
    border-radius: .4rem;
    padding: 30px 20px;
}

.block-form input, 
.block-form select {
    height: 48px;
    font-size: .9rem;
    font-weight: 300;
    border-radius: .2rem;
    box-shadow: none;
    margin-bottom: 1rem;
    background: transparent;
}

.block-form input:focus, 
.block-form select:focus {
    background-color: transparent;
}

.block-form button {
    border: 0;
    background: var(--color-primary);
    height: 50px;
    font-weight: 300;
    transition: .5s;
}

.block-form button:hover {
    background: var(--color-primary);
}

.block-form .block-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.block-form .block-group .form-control:first-child {
    margin-right: 15px;
}

@media (max-width: 1200px ) {
    #property-registration .content-form {
        max-width: 100%;
        padding: 0 0 50px;
    }
}

@media (max-width: 500px ) {
    .block-form .block-group {
        flex-wrap: wrap;
    }

    .block-form .block-group .form-control:first-child {
        margin-right: 0;
    }
}

@media (max-width: 350px) {
    .block-form button {
        max-width: 100%;
    }
    .block-form button span {
        display: none;
    }
}