.label {
    position: absolute;
    top: 1rem;
    left: 1rem;
    padding: .4rem;
    border-radius: .4rem;
    z-index: 10;
    color: var(--color-white);
}

.label.green {
    background: var(--color-green);
}

.label.orange {
    background: var(--color-orange);
}