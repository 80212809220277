#analysis {
    position: relative;
    background-image: url("../../../assets/images/bg-property-resgistration2.jpg");
    background-attachment: fixed;
    background-position: top;
    background-size: cover;  
    background-repeat: no-repeat;

    height: 500px;
    padding: 1rem;
}

#analysis::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-black);
    opacity: .5;
}

#analysis,
#analysis .container, 
#analysis a {
    display: flex;
    justify-content: center;
    align-items: center;
}

#analysis .container {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    z-index: 1;
}

#analysis h2 {
    font-weight: 300;
    font-size: 2.5rem;
    color: var(--color-white);
}

#analysis h2 strong {
    display: block;
    font-size: 2.6rem;
    color: var(--color-yellow);
}

#analysis p {
    color: var(--color-white);
    font-size: 1.2rem;
    max-width: 50%;
}

@media(max-width: 767px) {
    #analysis h2 strong {
        display: initial;
    }

    #analysis p {
        max-width: 100%;
    }
}