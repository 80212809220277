.imovel-card {
    position: relative;
    display: grid;
    width: 100%;
    max-width: 335px;
    height: 480px;
    background: var(--color-white);
    border-radius: .4rem;
    overflow: hidden;
    margin-bottom: 2rem;
    transition: .5s;
}

.imovel-card:hover {
    box-shadow: 0 0 30px rgb(0 0 0 / 12%);
}

.imovel-card .box-buttons {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: var(--color-white);
    cursor: pointer;
    z-index: 10;
}

.imovel-card .box-buttons i:hover {
    color: var(--color-yellow);
}
.imovel-card .box-buttons .fas {
    color: var(--color-yellow);
}

.imovel-card .box-buttons i:last-child {
    margin-left: 1rem;
}

.imovel-card .box-image {
    position: relative;
    overflow: hidden;
    height: 100%;
    max-height: 250px;

    background: #e6e6e6;
}

.imovel-card .box-image img {
    height: 250px;
    object-fit: cover;
    margin: 0 auto;
    display: block;
}

/* .owl-carousel .owl-item img {
    width: inherit !important;
} */

.imovel-card .box-image::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 60%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.35);
    background: -moz-linear-gradient(top, rgba(0,0,0,0.35) 0%, transparent 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.35) 0%, transparent 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.35)), color-stop(100%, transparent));
    background: linear-gradient(to bottom, rgba(0,0,0,0.35) 0%, transparent 100%);
    z-index: 2;
    pointer-events: none;
}

/* .imovel-card .box-image .carousel-nav{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 .5rem;
    pointer-events: none;
} */

/* .imovel-card .box-image .carousel-nav span {
    background: transparent;
    color: transparent;
    pointer-events: all;
    cursor: pointer;
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50% 50%;
    transition: .5s;
}

.imovel-card:hover .carousel-nav span {
    background: var(--color-primary-light);
    color: var(--color-white);
} */

.imovel-card .wrapper {
    padding: 1rem;
    border-bottom: 1px solid var(--color-grey-light);
}

.imovel-card .wrapper h2 {
    font-size: 1.2rem;
    margin: 0;
}

.imovel-card .wrapper h2 a {
    color: var(--color-blue);
}

.imovel-card .wrapper h3 {
    font-size: 1rem;
    color: var(--color-grey );
    margin: 1rem 0;
}

.imovel-card .wrapper h3 span {
    font-size: 1.4rem;
    color: var(--color-primary);
    margin-right: 1rem;
}

.imovel-card .wrapper ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .8rem;
}

.imovel-card .wrapper ul li {
    display: flex;
    align-items: center;
}

.imovel-card .wrapper ul li i {
    width: 25px;
    height: 25px;
    color: var(--color-grey);
    font-size: .7rem;
    background: var(--color-background);
    border-radius: 50% 50%;
    margin-right: .4rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.imovel-card .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.imovel-card .footer address {
    margin: 0;
    font-size: .9rem;
}

.imovel-card .footer address svg {
    margin-right: .2rem;
    color: var(--color-grey );
}

.imovel-card .footer a {
    background: var(--color-primary-light);
    color: var(--color-white);
    padding: .4rem .7rem;
    font-size: .8rem;
    border-radius: 1.5rem;
}