#sidebar-map {
    position: fixed;
    width: 100%;
    height: 100vh;
    padding: 0px 15px;
    top: 0;
    right: -9999px;
    z-index: 99999;
    transition: right .3s;
    background: var(--color-white);
    overflow: auto;
}

#sidebar-map .fa-times-circle-o {
    font-size: 25px;
    margin: 15px 0;
    color: var(--color-primary);
}

.open-sidebar-map{
    right: 0 !important; 
}