.especial-card {
    position: relative;
    max-width: 360px;
    height: 350px;
    color: var(--color-grey-light);
    background: var(--color-blue-dark);
    margin: 0 auto;
    border-radius: .4rem;
    overflow: hidden;
}

.especial-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-secondary);
    opacity: .3;
}

.especial-card:hover a {
    background: rgba(29, 24, 25, .5);
}

.especial-card a {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .5s;
    z-index: 1;
}

.especial-card a:hover {
    background: rgba(29, 24, 25, .5);
}

.especial-card .box-image {
    overflow: hidden;
    max-height: 237px;
}

.especial-card .box-image img {
    height: 237px;
    object-fit: cover;
    margin: 0 auto;
}

.especial-card .box-buttons {
    position: absolute;
    z-index: 1;
    top: 1rem;
    right: 1rem;
}

.especial-card .box-buttons i {
    font-size: 1.3rem;
    cursor: pointer;
    transition: .5s;
}

.especial-card .box-buttons i:hover {
    color: var(--color-yellow);
}

.especial-card .box-buttons .fas {
    color: var(--color-yellow);
}

.especial-card .box-buttons i:first-child {
    margin-right: 15px;
}

.especial-card .wrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 1rem;
    pointer-events: none;
    z-index: 1;
}

.especial-card .wrapper h2 {
    text-transform: inherit;
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--color-white);
}

.especial-card .wrapper address {
    margin-top: 1rem;
    position: relative;
}

.especial-card .wrapper address::before {
    position: absolute;
    content: '';
    width: 100px;
    top: -0.7rem;
    left: 0;
    border-bottom: 4px double var(--color-yellow);
}

.especial-card .wrapper .values {
    color: var(--color-yellow);
    font-size: 1.1rem;
    margin-bottom: .1rem;
}

.especial-card .wrapper .values span:first-child {
    margin-right: 1rem;
}

.especial-card .wrapper ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .9rem;
}

.especial-card .wrapper ul li:nth-child(2) {
    margin: 0 0,5rem;
}