#interprise {
    padding: 1.2rem 0 2rem;    
}

#interprise h1 {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1.2rem;
}

#interprise .result-card {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    min-height: 80vh;
}

.section-pagination {
    padding: 20px 0;    
    overflow: auto;
}

.section-pagination .content-pagination {
    margin: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-pagination .content-pagination .pagination {
    margin: 0;
}

.section-pagination .content-pagination .pagination li {
    border: 1px solid var(--color-grey-light);
    padding: 5px 15px;
    cursor: pointer;
}

.section-pagination .content-pagination .pagination li:first-child {
    border-radius: .5rem 0 0 .5rem;
}

.section-pagination .content-pagination .pagination li:last-child {
    border-radius: 0 .5rem .5rem 0;
}

.section-pagination .content-pagination .pagination li.active {
    border: 1px solid var(--color-primary-light);
    background: var(--color-primary-light);
}

.section-pagination .content-pagination .pagination li a {
    color: var(--color-text-base);
    font-weight: 300;
}

.section-pagination .content-pagination .pagination li a:hover {
    color: var(--color-primary-light);
}

.section-pagination .content-pagination .pagination li.active a {
    color: var(--color-white);
}

.section-pagination .content-pagination .pagination li.active a:hover {
    color: var(--color-white);
}

@media(max-width: 767px) {
    .section-pagination .content-pagination {
        justify-content: flex-start;
    }
}
