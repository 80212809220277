#privacy-topo {
    background-image: url("../../../assets/images/bg-edificio.jpg");
    background-attachment: fixed;
    background-position: top;
    background-size: cover;  
    background-repeat: no-repeat;

    width: 100%;
    height: auto;
    padding-top: 50px;

    position: relative;
    display: flex;
    justify-content: space-between;
}

#privacy-topo::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-black);
    opacity: .5;
}

#privacy-topo .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

#privacy-topo .content {
    position: relative;
    z-index: 1;
    padding: 50px 0;
    max-width: 80%;
}

#privacy-topo .content h1 {
    font-size: 2.7rem;
    font-weight: 300;
    color: var(--color-white);
}

#privacy-topo .content h1 strong {
    font-size: 2.6rem;
    color: var(--color-yellow);
}

#privacy-topo .content p {
    font-size: 1.2rem;
    font-weight: 300;
    color: var(--color-white);
}

#privacy-topo button {
    width: 50%;
    background: var(--color-primary);
    color:#fff;
    border: none;
    margin: 0;
}

#privacy-topo button:hover {
    background: var(--color-primary);    
}

#privacy-topo button:disabled {
    background: #aaa;    
}

#privacy-topo button:focus,
#privacy-topo button:active {
    color: #fff;
    background-color: var(--color-primary) !important;
    border-color: inherit;
    box-shadow: none !important
}

@media(max-width: 991px) {
    #privacy-topo {
        height: initial;
    }
}


