#enterprises-topo {
    background: rgba(235, 236, 236, .47);
    padding: 1rem 0;
    overflow: auto;
}

#enterprises-topo .content {
    display: flex;
    justify-content: center;
    width: 400px;
    margin: 0 auto;
}

#enterprises-topo .content .form-control {
    margin: 0 1rem;
}