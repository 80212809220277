:root {
    --color-primary: #931914;
    --color-primary-light: #d11b17;
    --color-secondary: #201e1e; 

    --color-background: #f5f5f5;
    --color-background-light: #efefef;
    --color-background-dark: #131b20;

    --color-white: #ffffff;
    --color-black: #000;
    --color-yellow: #ffc107;
    --color-orange: #d8841b;
    --color-green: #7dbd00;
    --color-green-light: #0fca98;
    --color-green-dark: #428270;
    --color-grey: #495057;
    --color-grey-light: #ececec;
    --color-grey-secondary: #ced4da;
    --color-blue: #3c5e7f;
    --color-blue-dark: #1c2939;
}
  
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: var(--color-background);
}

body,
input,
button,
textarea {
    font-size: 1rem;
    font-weight: 300;
    font-family: Roboto !important;
    color: var(--color-secondary);
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none !important;
}

/* REACT LOADING MASK */
.rc-loading::after {
    background-color: var(--color-grey-light) !important;
    border-radius: .4rem;
}

/* REACTSTRAP */
.breadcrumb {
    background: transparent;
    margin: 0;
    font-size: .85rem;
    padding: 12px 0;
}

.breadcrumb a {
    color: var(--color-grey);
    transition: .5s;
    font-weight: 400;
}

.breadcrumb a:hover {
    color: var(--color-grey);     
}

.container {
    padding: 0 15px;
}

.form-control {
    height: 46px;
}

.form-control:focus {
    box-shadow: none;
    border-color: var(--color-primary);
}

/* MATERIAL UI */
.MuiFormControlLabel-root {
    margin: 0 !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: var(--color-primary-light) !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: var(--color-background-dark) !important;
}

.MuiTypography-body1 {
    font-weight: 300 !important;
}

/* SELECT 2 */
.select2-container--default .select2-search--dropdown .select2-search__field {
    outline: none;
    border: 1px solid var(--color-primary);
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: var(--color-primary);
    color: var(--color-white);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    display: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding: 6px 12px;
}

.select2-container {
    z-index: 99999;
    width: 100% !important;
}

.select2-container--default .select2-results > .select2-results__options {
    max-height: 210px;
}

/* MEDIAS QUERIES */
@media (min-width: 1281px) {
    .container {
        max-width: 1240px;
    }
}