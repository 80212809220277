.breadcrumb-analysis {
    padding-top: 88px;
    background: transparent !important;
    background-color: transparent;
}

/* MEDIAS QUERIES */
@media (min-width: 1200px) {
    .breadcrumb-interprise .container {
        max-width: 1240px;
    }
}