.breadcrumb-content {
    padding-top: 88px;
    background: var(--color-grey-light);
}

/* MEDIAS QUERIES */
@media (min-width: 1200px) {
    .breadcrumb-content .container {
        max-width: 1300px;
    }
}