#especial-rent {
    padding:  0 0 2rem;
    background: var(--color-background-dark);
}

#especial-rent .carousel {
    margin-top: -4rem;
}

#especial-rent .carousel .container >.loader {
    display: flex;
    align-self: center;
    justify-content: center;
}


#especial-rent .carousel-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    margin-top: 1rem;
}

#especial-rent .carousel-nav span {
    padding: .5rem 1rem;
    margin: 0 1rem;
    border-radius: .4rem;
    border: 1px solid var(--color-grey);
    cursor: pointer;
    transition: .5s;
}

#especial-rent .carousel-nav span:hover {
    border: 1px solid var(--color-white);
}

#especial-rent .carousel-nav span i {
    color: var(--color-grey);
    transition: .5s;
}

#especial-rent .carousel-nav span:hover i {
    color: var(--color-white);
}