header {
    background: var(--color-white);
    width: 100%;
    position: fixed;
    z-index: 2000;
    box-shadow: 0 5px 30px rgb(0 22 84 / 10%)
}

header .container { 
    display: flex;   
    justify-content: space-between;
    align-items: center;
}

header img {
    max-width: 125px;
    transition: .5s;
}

header .btn-menu {
    min-width: 90px;
    padding: 2rem .5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color-white);
    background: var(--color-primary-light);
    opacity: .8;

    text-transform: uppercase;    
    transition: .5s;
    cursor: pointer;
}

header .btn-menu:hover {
    opacity: 1;
}

.show-header {
    background: var(--color-white);
}

.show-header img {
    max-width: 95px;
}

.show-header #nav-header ul li a,
.show-header .btn-menu  {
    padding: 1.1rem .5rem;
}

.show-header  #nav-header ul li a::after {
    top: 3rem;
}

@media(max-width: 767px) {
    header .btn-menu {
        background: transparent;
        color: var(--color-secondary);
    }
}