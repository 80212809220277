.form-contact input,
.form-contact textarea,
.form-contact button {
    margin-bottom: 1rem;
    height: 48px;
    font-weight: 300;
}

.form-contact textarea {
    height: auto !important;
}

.form-contact button {
    width: 100%;
    background: var(--color-primary);
    border: none;
    margin: 0;
}

.form-contact button:hover {
    background: var(--color-primary);    
}

.form-contact button:focus,
.form-contact button:active {
    color: #fff;
    background-color: var(--color-primary) !important;
    border-color: inherit;
    box-shadow: none !important
}