#header-home {
    background: rgba(29, 24, 25, .4);
    width: 100%;
    position: fixed;
    z-index: 100;
}

#header-home .container { 
    display: flex;   
    justify-content: space-between;
    align-items: center;
}

#header-home img {
    max-width: 125px;
    transition: .5s;
}

#header-home .btn-menu {
    min-width: 90px;
    padding: 2rem .5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color-white);
    background: var(--color-primary-light);
    opacity: .8;

    text-transform: uppercase;    
    transition: .5s;
    cursor: pointer;
}

#header-home .btn-menu:hover {
    opacity: 1;
} 

#header-home.show-header-home {
    background: rgba(29, 24, 25, .9);
}

#header-home.show-header-home img {
    max-width: 95px;
}

#header-home.show-header-home #nav-header-home ul li a,
#header-home.show-header-home .btn-menu {
    padding: 1.1rem .5rem;
}

#header-home.show-header-home  #nav-header-home ul li a::after {
    top: 3rem;
}

@media(max-width: 767px) {
    #header-home .btn-menu {
        background: transparent;
    }
}