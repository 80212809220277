#popular-properties {
    padding: 3rem 0;
    background: var(--color-white);
}

#popular-properties .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

#popular-properties .container .column:last-child {
    padding-left: 3rem;
}

#popular-properties .container h2 {
    font-size: 1.8rem;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--color-secondary);
    margin-bottom: 2rem;
}

#popular-properties .container h2 strong {
    display: block;
    color: var(--color-primary-light);
}

#popular-properties .cards-popular-properties {
    display: flex;
    margin-bottom: 1rem;
}

#popular-properties .cards-popular-properties li {
    position: relative;
    border-radius: .4rem;
    width: 250px;
    height: 429px;
    overflow: hidden;
    transition: .5s; 
}

#popular-properties .cards-popular-properties li:hover {
    opacity: .90;
}

#popular-properties .cards-popular-properties li:nth-child(2) {
    margin: 0 1rem;
}

#popular-properties .cards-popular-properties li::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: var(--color-secondary);
    opacity: .5;
    z-index: 1;
}

#popular-properties .cards-popular-properties li img {
    position: absolute;
    top: 0;
}

#popular-properties .cards-popular-properties li a {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1rem;
    color: var(--color-white);
    top: 0;
    z-index: 2;
    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#popular-properties .cards-popular-properties li a h3 {
    font-size: 1.3rem;
    text-align: center;
}

#popular-properties .cards-popular-properties li h3 span {
    color: var(--color-yellow);
}

#popular-properties .cards-popular-properties li h6 {
    background: var(--color-orange);
    padding: .5rem;
    border-radius: .4rem;
    margin-top: 2rem;
}

#popular-properties .cards-popular-properties li:nth-child(3) h6{
    background: var(--color-green);
}

#popular-properties .list-popular-properties li {
    font-size: 1rem;
    padding: .4rem 0;
}

#popular-properties .list-popular-properties li a {
    color: var(--color-secondary);
    transition: .5s;
}

#popular-properties .list-popular-properties li a:hover {
    color: var(--color-primary-light);
}

#popular-properties .list-popular-properties li a svg {
    color: var(--color-primary-light);
}

@media(max-width: 991px) {
    #popular-properties .container .column:nth-child(1) {
        display: none;        
    }

    #popular-properties .container .column:last-child {
        padding-left: 0;
    }    
}