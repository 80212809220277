#nav-header {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

#nav-header ul li a {
    position: relative;
}

#nav-header ul li a::after {
    position: absolute;
    content: '';
    width: 0;
    top: 3.9rem;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 4px double var(--color-yellow);
    transition: .5s;
}

#nav-header ul li a:hover::after {    
    width: 40px;
}

#nav-header ul,
#nav-header ul li,
#nav-header ul li a {
    display: flex;
}

#nav-header ul li,
#nav-header ul li a {
    align-items: center;
    text-transform: uppercase;
}

#nav-header ul li a {
    color: var(--color-secondary);
    padding: 2rem .5rem;
    transition: .5s;
    cursor: pointer;
}

#nav-header ul li a svg,
#nav-header ul li i {
    margin-right: .5rem;
    color: var(--color-secondary);
    transition: .4s;
}

#nav-header ul li a span {
    color: var(--color-primary);
    background: var(--color-yellow);
    border-radius: 2px;
    min-width: 13px;
    height: 13px;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
    margin-top: 3px;
    margin-right: 5px;
    margin-left: -10px;
    line-height: 1.4;
    padding: 0 2px;
}

@media(max-width: 991px) {
    #nav-header .modality {
        display: none;
    }
}

@media(max-width: 767px) {
    #nav-header {
        display: none;
    }
}