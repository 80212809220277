#comparatives {
    padding: 88px 0 50px;
}


#comparatives .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}

#comparatives .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    box-shadow: 1px 4px 6px rgba(0,0,0,0.2);
}

#comparatives .content .fa-trash{
    color: var(--color-primary-light);
}

#comparatives .content .code {
    color: var(--color-primary-light);
}

#comparatives .content .clear {
    border: 0;
    background-color: var(--color-background);
}

#comparatives .content .card-comparatives{
    background: var(--color-white);
}

#comparatives .content .card-comparatives ul {
    padding: 0;
    margin: 0;
}

#comparatives .content .card-comparatives ul li:first-child {   
    height: 240px;
    padding: 0 1px;
}

#comparatives .content .card-comparatives ul li:first-child img {   
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#comparatives .content .card-comparatives ul li {
    border-bottom: 1px dashed var(--color-grey-light);
    border-right: 1px dashed var(--color-grey-light);
    min-height: 55px;
    width: 300px;
    color: var(--color-grey);
    display: flex;
    justify-content: center;
    align-items: center;
     
    font-weight: 400;
    font-size: 1rem;

    padding: 0 15px;
}

#comparatives .content .card-comparatives ul .remove-comparate {
    cursor: pointer;
    transition: .4s;
}

#comparatives .content .card-comparatives ul .remove-comparate:hover {
    color: var(--color-primary);
}


#comparatives .content .col-main {
    border-right: 2px solid var(--color-primary-light);    
}

#comparatives .content .col-main ul li {
    justify-content: start;
    border: 0;
    padding-left: 30px;
    background: var(--color-background);
}

#comparatives .content .col-main ul li:first-child {
    padding-top: 100px;
    padding-left: 30px;
}

#comparatives .content .col-main .title {
    padding-top: 1rem;
    font-size: 1rem;
    color: var(--color-primary);
    font-weight: 400;
    display: inline-block;
    text-transform: uppercase;
}

#comparatives .content .col-main .title:after {
    content: "";
    display: table;
    margin: 5px auto;
    width: 25%;
    height: 2px;
    background-color: var(--color-yellow);
    -webkit-transition: .4s;
    transition: .4s;
}

#comparatives .no-comparatives {
    padding: 3rem;
}

#comparatives .no-comparatives h2 {
    font-size: 1.4rem;
    text-align: center;
}

#comparatives .no-comparatives p {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 300;
}

#comparatives .no-comparatives p strong {
   
}

/* MEDIAS QUERIES */
@media (min-width: 1200px) {
    #comparatives .container {
        max-width: 1560px;
    }
}