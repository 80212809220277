#search-result-topo {
    background: rgba(235, 236, 236, .47);
    padding: 1rem 0;
    overflow: auto;
}

#search-result-topo .content {
    display: flex;
    justify-content: center;
    width: 400px;
    margin: 0 auto;
}

#search-result-topo .content button {
    border: 1px solid var(--color-grey-secondary);
    border-radius: .2rem;
    height: 46px;
    padding: 0 1rem;
    background: var(--color-white);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: .5s;
}

#search-result-topo .content button.active {
    border: 1px solid var(--color-primary);
    background: rgba(174, 25, 20, .22);
    color: var(--color-primary);
}

#search-result-topo .content .form-control {
    max-width: 150px;
    margin: 0 1rem;
}


/* MEDIAS QUERIES */
@media (min-width: 1200px) {
    #search-result-topo  .container {
        max-width: 1300px;
    }
}

@media (min-width: 991px) {
    #search-result-topo  .container .btn-filtros-mobile,
    #search-result-topo  .container .btn-map-mobile {
        display: none;
    }
}

@media (max-width: 991px) {
    #search-result-topo  .container .btn-filtros,
    #search-result-topo  .container .btn-map {
        display: none;
    }
}