.breadcrumb-interprise {
    padding-top: 88px;
    background: var(--color-grey-light);
}

/* MEDIAS QUERIES */
@media (min-width: 1200px) {
    .breadcrumb-interprise .container {
        max-width: 1240px;
    }
}