#similar-property {
    margin-top: 3rem;
}

#similar-property h2 {
    font-size: 1.2rem;
    font-weight: 400;
}

#similar-property .block-card-similar {
    display: block;
}

#similar-property .card-similar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: var(--color-white);
    padding: .4rem;
    border-radius: .4rem;

    transition: .5s;
    margin-bottom: 1rem;
}

#similar-property .card-similar:hover {
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
}

#similar-property .card-similar img {
    width: 100px;
    height: 80px;
    object-fit: cover;
    border-radius: .2rem;
    margin-right: 1rem;
}

#similar-property .card-similar h2 {
    font-size: .9rem;
    margin: 0 0 .3rem;
    color: var(--color-blue);
    transition: .5s;
}

#similar-property .card-similar h2:hover {
    color: var(--color-primary);
    cursor: pointer;
}

#similar-property .card-similar address {
    font-size: .7rem;
    margin: 0;
}

#similar-property .card-similar h3 {
    font-size: 1.4rem;
    margin: 0;
    color: var(--color-primary);
}

@media(max-width: 991px) {
    #similar-property .block-card-similar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
}