#property-content {
    padding: 88px 0 30px;
}

#property-content .block-details{
    max-width: 1240px !important;
    margin: 0 auto;
    padding: 3rem 15px 0;
    height: 100%;
    display: flex;
}

#property-content .block-details .content {
    width: 75%;
}

#property-content .block-details .contact {
    min-width: 25%;
    padding-left: 30px;
}

#property-content-error {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
}

#property-content-error .card-error404 {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 100px 0;
    padding: 40px 30px;
    display: inline-table;
}

#property-content-error .card-error404 h1 .fa{
    font-size: 30px;
    margin-right: 15px;
}

#property-content-error .card-error404 h1 {
    font-size: 26px;
    color: var(--color-primary);
}

#property-content-error .card-error404 h2 {
    font-size: 18px;
    color: #666666;
}

#property-content-error .card-error404 a {
    color: var(--color-primary);
}

@media(max-width: 991px) {
    #property-content .block-details {
        flex-wrap: wrap;
    }

    #property-content .block-details .content,
    #property-content .block-details .contact {
        width: 100%;
    }

    #property-content .block-details .contact {
        padding-left: 0;
        margin-top: 2rem;
    }
}