#spread, 
#spread a {
    display: flex;
    justify-content: center;
    align-items: center;
}

#spread {
    flex-direction: column;
    margin-bottom: 2rem;
}

#spread a {
    height: 46px;
    margin: 0 auto;
    background: var(--color-primary);
    border: 0;
}