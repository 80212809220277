.box-social-medias {
    position: fixed;
    padding: 0 1rem;    
    height: 100vh;
    font-size: 1.1rem;
    font-weight: 300;
    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.box-social-medias a {
    color: var(--color-grey-secondary);
    transition: .5s;
}

.box-social-medias a:hover {
    color: var(--color-primary-light);
    position: relative;
}

.box-social-medias a:nth-child(2) {
    margin: .6rem 0;
}

.box-social-medias .first-icon {
    position: relative;
}

.box-social-medias .first-icon::before {
    position: absolute;
    content: '';
    width: 1px;
    height: 80px;
    background: var(--color-grey-secondary);
    top: -4rem;
    left: 50%;
    transform: translateY(-50%);    
}

@media(max-width: 767px) {
    .box-social-medias {
        display: none;
    }
}