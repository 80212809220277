#sidebar-form {
    position: fixed;
    width: 100%;
    max-width: 310px;
    height: 100vh;
    padding: 0px 15px;
    top: 0;
    left: -999px;
    z-index: 99999;
    transition: left .3s;
    background: var(--color-white);
    overflow: auto;
}

#sidebar-form .fa-times-circle-o {
    font-size: 25px;
    margin: 15px 0;
    color: var(--color-primary);
}

.open-sidebar-form {
    left: 0 !important;    
}