.headline {
    width: 100%;
    padding: 2rem 0;
    text-align: center;
}

.headline h2 {
    position: relative;
    font-size: 2rem;
    padding: 0 .9rem;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--color-secondary);
}

.headline h2::before,
.headline h2::after {
    position: absolute;
    content: '';
    width: 10%;
    top: 3.5rem;    
    border-bottom: 4px double var(--color-primary-light);
}

.headline h2::before { 
    left: 0;
}

.headline h2::after { 
    right: 0;
}

.headline p {
    font-size: .9rem;
    max-width: 50%;
    margin: 0 auto;
    color: var(--color-grey);
}

@media(max-width: 767px) {
    .headline p {
        max-width: 80%;          
    }
}