#search-result {
    padding: 1.2rem 0 2rem;    
}

#search-result h1 {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1.2rem;
}

#search-result .search-result-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
}

/* FORM */
#search-result .search-result-wrapper #form-sidebar {
    width: 36%;
    height: auto;
    pointer-events: all;
    opacity: 1;
    transition: 0.2s;
}

.hidden-form {
    width: 0 !important;
    height: 0 !important;
    pointer-events: none !important;
    opacity: 0 !important;
}

/* CARDS */
#search-result .search-result-wrapper #result-card {
    position: relative;
    width: 100%;
    padding: 0;   

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: .2s;
}

#result-card .result-card-content {
    min-height: 50vh;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.view-map {
    width: 32% !important;
    height: 100vh;
    overflow-y: scroll;
}

#search-result .search-result-wrapper #result-card .item:last-child .imovel-card {
    margin-bottom: 0;
}

/* MAPA */
#search-result .search-result-wrapper #result-map {   
    width: 68%;
    height: 100vh;
    right: 0;
    transition: .6s;    
}

.hidden-map {
    position: absolute;
    right: -3333px !important;
}

#result-card .content-pagination {
    display: table;
    margin:  0 auto;
    padding: 15px 0;
}

#result-card .content-pagination .pagination {
    margin: 0;
}

#result-card .content-pagination .pagination li {
    border: 1px solid var(--color-grey-light);
    padding: 5px 15px;
    cursor: pointer;
}

#result-card .content-pagination .pagination li:first-child {
    border-radius: .5rem 0 0 .5rem;
}

#result-card .content-pagination .pagination li:last-child {
    border-radius: 0 .5rem .5rem 0;
}

#result-card .content-pagination .pagination li.active {
    border: 1px solid var(--color-primary-light);
    background: var(--color-primary-light);
}

#result-card .content-pagination .pagination li a {
    color: var(--color-text-base);
    font-weight: 300;
}

#result-card .content-pagination .pagination li a:hover {
    color: var(--color-primary-light);
}

#result-card .content-pagination .pagination li.active a {
    color: var(--color-white);
}

#result-card .content-pagination .pagination li.active a:hover {
    color: var(--color-white);
}


/* MEDIAS QUERIES */
@media (min-width: 1200px) {
    #search-result .container {
        max-width: 1560px;
    }
}

@media (max-width: 991px) {
    #search-result .search-result-wrapper #form-sidebar  {
       width: 0%;
    }
}