.form-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    max-width: 800px;
    
    background: rgba(29, 24, 25, .4);
    padding: .7rem;

    border-radius: .4rem;
}

.form-box .col  {
    padding-left: 5px;
    padding-right: 5px;
}

.form-box select,
.form-box input {
    height: 46px;
    width: 100%;
    background-color: transparent;
    color: var(--color-white);
    border-radius: 0;
}

.form-box select:focus,
.form-box input:focus {
    /* background-color: transparent; */
    /* color: var(--color-white); */

    box-shadow: none;
    border-color: var(--color-white);
}

.col-modality {
    max-width: 120px !important;
}

.col-btn {
    max-width: 130px !important;
}

.form-box button {
    height: 46px;
    width: 100%;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    border-radius: 0;
    transition: all .5s ease;
    opacity: .8;
}

.form-box button:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);    
    opacity: 1;
}

.form-box button:focus {
    box-shadow: none;
    background-color: var(--color-primary);
    border-color: var(--color-primary);  
}

.form-box button.btn-avancado,
.form-box button.btn-code {
    background-color: transparent;
    border-color: var(--color-yellow);
}

.form-box button.btn-code {
    width: 100%;
}

.form-box input::-webkit-input-placeholder {
    color: var(--color-grey-secondary);
}
 
.form-box input:-moz-placeholder { /* Firefox 18- */
    color: var(--color-grey-secondary);  
}
 
.form-box input::-moz-placeholder {  /* Firefox 19+ */
    color: var(--color-grey-secondary);  
}
 
.form-box input:-ms-input-placeholder {  
    color: var(--color-grey-secondary);  
}

@media(max-width: 767px) {
    .form-box .col {
        display: contents;
        margin-bottom: .5rem;
    }

    .form-box select,
    .form-box input {
        margin-bottom: .5rem;
    }
}