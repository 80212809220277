.content-headline {
    background: var(--color-background);
    width: 100%;
    padding: 4.2rem 0 5rem;
    text-align: center;
}

.content-headline h2 {
    position: relative;
    font-size: 1.8rem;
    padding: 0 .9rem;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--color-secondary);
}

.content-headline h2::before,
.content-headline h2::after {
    position: absolute;
    content: '';
    width: 10%;
    top: 3.5rem;    
    border-bottom: 4px double var(--color-primary-light);
}

.content-headline h2::before { 
    left: 0;
}

.content-headline h2::after { 
    right: 0;
}

.content-headline p {
    font-size: 1rem;
    color: var(--color-grey);
}