footer {
    position: relative;
    padding: 4rem 0 2rem;
    background: var(--color-background-dark);
    color: var(--color-grey-light);
}

footer .container,
footer .col-left,
footer .col-right {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
footer .col-left,
footer .col-right {
    flex-wrap: wrap;
}

footer ul li {
    padding: .3rem 0;
}

footer ul li,
footer ul li a {
    position: relative;
    color: var(--color-grey-secondary);
    text-transform: uppercase;
    font-size: .8rem;
    transition: .5s;
}

footer ul li h2,
footer ul li h2 a {
    font-size: .85rem;
    font-weight: 400;
    color: var(--color-white);
    transition: .5s;
}

footer ul li h2 a:hover {
    color: var(--color-primary-light) !important;
}

footer .col-left ul li a:hover {
    color: var(--color-grey-light);
}

footer .col-left ul li > a::before {
    position: absolute;
    content: '';
    bottom: -3px;
    width: 0;
    height: 1px;  
    background: var(--color-primary-light);
    transition: .5s;
}

footer .col-left ul li > a:hover::before {
    width: 100%;
}

footer .col-left ul,
footer .col-right ul {
    margin: 0 2rem 2rem 0;
}

/* footer .col-left ul:last-child,
footer .col-right ul:last-child {
    margin: 0 0 2rem 0;
} */

footer .col-right {
    border-left: 1px solid var(--color-grey-secondary);
    padding-left: 2rem;
}

footer .col-right ul li h2 {
    text-transform: uppercase;
}

footer .col-right ul li {
    text-transform: inherit;
    font-size: .9rem;
}

footer .col-right .content-logo li:first-child {
    margin-bottom: 1rem;
}

footer .col-right .content-logo li:last-child {
    color: var(--color-white);
}

footer .col-right ul li span {
    color: var(--color-white);
}

footer .col-right .content-logo li,
footer .col-right .content-logo li a {
    font-size: 1.34rem;
    line-height: 1.3;
    color: var(--color-yellow);
}

footer .col-right .content-logo li a {
    color: var(--color-yellow);
}

footer .col-right .content-logo li span,
footer .col-right .content-logo li a span {
    display: block;
    font-size: .7rem;
}

footer .col-right .content-logo li:last-child {
    font-size: .9rem;
}

footer .col-right .footer-medias a {
    margin-right: 1.2rem;
    font-size: 1rem;
}

footer .col-right .footer-medias a:hover {
    color: var(--color-primary-light);
}

footer .copy-right p {
    color: var(--color-grey-secondary);
    font-size: .9rem;
    text-align: center;
    margin: 0;
    padding: 4rem 0 0;
}

footer svg {
    position: fixed;
    width: 50px;
    height: 50px;
    right: 2rem;
    bottom: 1rem;
    padding: .5rem;
    color: var(--color-yellow);
    background: rgba(29, 24, 25, .9);
    border: 1px solid var(--color-grey-light);
    border-radius: 50% 50%;
    z-index: 1000;
    opacity: 0;
    transition: .5s;
    cursor: pointer;
}

.show {
    opacity: 1;
}

@media(max-width: 991px) {
    footer .col-left, footer .col-right {
        width: 100%;
    }
}

@media(max-width: 650px) {
    footer .container{
        flex-wrap: wrap;
    }

    footer .col-right {
        border: 0;
        padding: 0;
    }
}
