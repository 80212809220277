#widget-container {
    position: relative;
    margin-top: -3rem;
}

#widget-container .content {
    display: flex;    
    align-items: center;
    flex-direction: column;

    background: var(--color-white);
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
    max-width: 80%;
    padding: 2.5rem;
    margin: 0 auto;
}

#widget-container .content h2 {
    font-size: 1.5rem;
}

#widget-container .content p {
    text-align: center;
    font-size: .9rem;
    max-width: 700px;
}

#widget-container ul {
    display: flex;    
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

#widget-container ul li {
    text-align: center;
    margin-bottom: .5rem;
}

#widget-container ul li span {
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--color-primary);
}

@media(max-width: 767px) {
    #widget-container .content {
        padding: 1.2rem;
    }
}