#gallery-content {
    position: relative;
    display: flex;
}

#gallery-content button {
    position: absolute;
    bottom: 15px;
    left: 2rem;
    z-index: 2;

    border: none;
    border-radius: .5rem;
    background: var(--color-white);
    padding: 1rem;

    line-height: 1.2px;

    display: flex;
    justify-content: center;
    align-items: center;
}

#gallery-content .gallery img {
    width: 100% !important;
    height: 380px;
    object-fit: cover;
}

#gallery-content .gallery  .carousel-nav{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 .5rem;
    pointer-events: none;
}

#gallery-content .gallery  .carousel-nav span {
    background: rgba(255, 255, 255, .24);
    color: #fff;
    box-shadow: 0 5px 24px rgb(31 37 59 / 15%);
    pointer-events: all;
    cursor: pointer;
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50% 50%;
    transition: .5s;
}
