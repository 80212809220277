#favorites {
    padding: 88px 0 30px;    
}

#favorites .container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    min-height: 50vh;
}

#favorites .no-favorites {
    padding: 3rem;
}

#favorites .no-favorites h2 {
    font-size: 1.4rem;
    text-align: center;
}

#favorites .no-favorites p {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 300;
}

#favorites .no-favorites p strong {
   
}