#especial-sale {
    padding:  0 0 2rem;
    background: var(--color-grey-light);
}

#especial-sale .carousel {
    margin-top: -4rem;
}

#especial-sale .carousel .container >.loader  {
    display: flex;
    align-self: center;
    justify-content: center;
}

#especial-sale .carousel-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    margin-top: 1rem;
}

#especial-sale .carousel-nav span {
    padding: .5rem 1rem;
    margin: 0 1rem;
    border-radius: .4rem;
    border: 1px solid var(--color-grey-secondary);
    cursor: pointer;
    transition: .5s;
}

#especial-sale .carousel-nav span:hover {
    border: 1px solid var(--color-grey);
}

#especial-sale .carousel-nav span i {
    color: var(--color-grey-secondary);
    transition: .5s;
}

#especial-sale .carousel-nav span:hover i {
    color: var(--color-grey);
}