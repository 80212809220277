#home-banner{
    padding-top: 100px;
    background-image: url('../../../assets/images/search-home.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    min-width: 100%;
    min-height: 100vh;
    overflow: hidden;
    
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--color-white);
    z-index: 0;
}

#home-banner::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 60%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.35);
    background: -moz-linear-gradient(top, rgba(0,0,0,0.35) 0%, transparent 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.35) 0%, transparent 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.35)), color-stop(100%, transparent));
    background: linear-gradient(to bottom, rgba(0,0,0,0.35) 0%, transparent 100%);
    z-index: 1;
}

#home-banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-secondary);
    opacity: .3;
}

#home-banner video {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
}

#home-banner .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2.5rem;
    z-index: 1;

    width: 100%;
    max-width: 750px;
}

#home-banner .content h1,
#home-banner .content h2 {
    text-transform: uppercase;
    font-weight: 300;
}

#home-banner .content h1 {
    font-size: 3.8rem;
    font-weight: 400;
}

#home-banner .content h2 {
    font-size: 1.2rem;
}

#home-banner .content p {
    font-size: 1.4rem;
    color: var(--color-yellow);
    font-weight: 400;
}

#home-banner .content > button {
    background: transparent;
    border: 0;
    padding: .8rem;
    color: var(--color-white);
    font-weight: 400;
    transition: .5s;
}

#home-banner .content > button:hover {
    color: var(--color-yellow);
}

@media(max-width: 767px) {
    #home-banner{
        background-image: url('../../../assets/images/search-home-mobile.jpg');
        background-position: -190px;
    }

    #home-banner .content {
        padding: 0 .9rem;
    }

    #home-banner .content h1 {
        font-size: 2rem;
    }

    #home-banner .content p {
        font-size: 1rem;
    }
}