.form-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    max-width: 800px;
    
    background: rgba(29, 24, 25, .4);
    padding: .7rem;

    border-radius: .4rem;
}

.form-box .col-modality,
.form-box .col-tipo {
    padding-left: 5px;
    padding-right: 5px;
}

.form-box select,
.form-box .select2-container--default .select2-selection--single,
.form-box .select2-container--default .select2-selection--single .select2-selection__rendered {
    height: 46px;
    width: 100%;
    background-color: transparent;
    color: var(--color-white);
    border-radius: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.3;
    outline: none;
}

.form-box select:focus {
    /* background-color: transparent; */
    /* color: var(--color-white); */

    box-shadow: none;
    border-color: var(--color-white);
}

.col-modality {
    max-width: 120px !important;
}

.col-tipo {
    max-width: 180px !important;
}

.col-btn-location {
    max-width: 130px !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.col-btn-location button:last-child {
    margin-left: .2rem;
    max-width: 42px;
}

.form-box button {
    height: 46px;
    width: 100%;
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 0;
    transition: all .5s ease;
    opacity: .8;
}

.form-box button:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);    
    opacity: 1;
}

.form-box button:focus {
    box-shadow: none;
    background-color: var(--color-primary);
    border-color: var(--color-primary);  
}

.form-box button.btn-code {
    background-color: transparent;
    border-color: var(--color-yellow);
}

.form-box button.btn-code {
    width: 100%;
}

#search-plus-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 0;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s;    
}

#search-plus-box hr {
    width: 98%;
    display: block;
    margin: 1rem auto;
    background: var(--color-primary);
}

#search-plus-box .line {
    display: flex;
    justify-content: space-between;
    align-items: center;      
    width: 100%;
}

#search-plus-box .line:last-child {
    margin-top: .5rem; 
}

.open-search-plus {
    opacity: 1 !important;
    height: 100% !important;
    pointer-events: all !important;
}

/* MEDIAS QUERIES */
@media(max-width: 767px) {
    .form-box .col {
        display: contents;
        padding: 0 !important;
        margin-bottom: .5rem;
    }

    .form-box .col-btn-location {
        padding: 0;
        width: 100%;
        max-width: initial !important;
    }

    .form-box select,
    .form-box .select2-container--default .select2-selection--single,
    .form-box .select2-container--default .select2-selection--single .select2-selection__rendered {
        margin-bottom: .5rem !important;
    }

    #search-plus-box .line {
        flex-direction: column;
    }
}