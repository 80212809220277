#benefits {
    padding: 0 0 3rem;    
}

#benefits .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; 
}

#benefits article {
    background: var(--color-white);
    width: 30%;
    height: 330px;
    border-radius: .4rem;
    padding: 2rem;
    transition: .5s;
}

#benefits article:hover {
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
}

#benefits article:nth-child(n + 4) {
    margin-top: 2rem;
}

#benefits article img {
    width: 3.5rem;
}

#benefits article h2 {
    font-size: 1.5rem;
    padding: 1rem 0;
}

#benefits article p {
    text-align: justify;
    font-size: 1rem;
}

@media(max-width: 991px) {
    #benefits .container {
        padding: 0;
    }

    #benefits article {
        width: 46%;
        margin: .9rem !important;
    }
}

@media(max-width: 767px) {
    #benefits .container {
        padding: 0 15px;
    }

    #benefits article {
        width: 100%;
        margin: .9rem 0 !important;
    }

    #benefits article:last-child {
        margin-bottom: 0 !important;
    }
}