#options-container {
    padding: 3rem 0;
}

#options-container .container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

#options-container article {
    background: var(--color-white);
    width: 45%;
    min-height: 324px;
    border-radius: .4rem;
    padding: 2rem;
    transition: .5s;
}

#options-container article:hover {
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
}

#options-container article img {
    width: 3.5rem;
}

#options-container article h2 {
    font-size: 1.5rem;
    padding: 1rem 0;
}

#options-container article p {
    font-size: 1rem;
}

@media(max-width: 991px) {
    #options-container article {
        width: 100%;
    }

    #options-container article:last-child {
        margin-top: 1rem;
    }
}