#property-registration {
    background-image: url("../../../assets/images/bg-property-resgistration.jpg");
    background-attachment: fixed;
    background-position: top;
    background-size: cover;  
    background-repeat: no-repeat;

    width: 100%;
    min-height: 600px;

    position: relative;
    display: flex;
    justify-content: space-between;
}

#property-registration::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-black);
    opacity: .5;
}

#property-registration .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#property-registration .content,
#property-registration .content-form {
    position: relative;
    z-index: 1;
    padding: 50px 0;
}

#property-registration .content {
    max-width: 50%;
    margin-right: .5rem;
}

#property-registration .content h1 {
    font-size: 2.7rem;
    font-weight: 300;
    color: var(--color-white);
}

#property-registration .content h1 strong {
    display: block;
    font-size: 2.6rem;
    color: var(--color-yellow);
}

#property-registration .content p {
    font-size: 1.2rem;
    font-weight: 300;
    color: var(--color-white);
}

@media(max-width: 1199px) {
    #property-registration .content {
        max-width: 60%;
    }
}

@media(max-width: 991px) {
    #property-registration .content h1 strong {
        display: initial;
    }
}

@media(max-width: 767px) {
    #property-registration .container {
        flex-wrap: wrap;
    }

    #property-registration .content {
        max-width: 100%;
        padding-bottom: 0;
        margin: 0;
    }
}