.fluid-whatsapp {
    position: fixed;
    z-index: 1000;
    bottom: -330px;
    transition: bottom .3s;
    right: 10px;
}

.fluid-whatsapp img {
    max-width: 65px;
    position: fixed;
    bottom: 15px;
    right: 5px;
    z-index: 900;
}

.fluid-whatsapp .content-form-fluid {
    background-color: var(--color-grey-secondary);
    padding: 10px;
    width: 200px;
    border-radius: 5px;
    margin-top: 15px;
    z-index: 1000;
    position: relative;
}

.fluid-whatsapp .content-form-fluid .fa-times {
    float: right;
    margin-bottom: 15px;
    cursor: pointer;
    color: #00264a;
}

.fluid-whatsapp .content-form-fluid .botao {
    width: 100%;
    background-color: var(--color-primary-light);
    margin-top: 15px;
}

.open-form {
    bottom: 15px;
}

.fluid-whatsapp .content-form-fluid .botao a{
color: white;
}