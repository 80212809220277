.box-buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.box-buttons button {
    border: 1px solid var(--color-grey-secondary);
    border-radius: .2rem;
    height: 46px;
    font-size: .85rem;
    padding: 0 1rem;
    background: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
}

.box-buttons button:last-child {
    margin-left: .5rem;
}

.box-buttons button.active {
    border: 1px solid var(--color-primary);
    background: rgba(174, 25, 20, .22);
    color: var(--color-primary);
}

.box-form {
    margin-top: 2rem;
    max-width: 400px;
}

.box-form .box-whatsapp {
    background: rgba(26, 121, 96, .27);
    margin: -1rem -1rem 2rem;
    border-radius: .4rem .4rem 0 0;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
}

.box-form .box-whatsapp i {
    color: var(--color-green-dark);
    font-size: 2.8rem;
    margin-right: .5rem;
}

.box-form .box-whatsapp h2 {
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--color-green-dark);
}

.box-form .box-whatsapp h2 a {
    color: var(--color-green-dark);
    font-size: 1.6rem;
}

